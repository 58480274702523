<template>
  <b-modal centered modal-class="save_brand_model"
           id="links-csv-export-modal-v2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('links-csv-export-modal-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[2.125rem] h-[2.25rem]" viewBox="0 0 34 33" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3506 10.85C25.7648 11.4358 24.815 11.4358 24.2293 10.85L18.5006 5.12132V21.3947C18.5006 22.2231 17.829 22.8947 17.0006 22.8947C16.1721 22.8947 15.5006 22.2231 15.5006 21.3947V5.12132L9.77186 10.85C9.18608 11.4358 8.23633 11.4358 7.65054 10.85C7.06476 10.2642 7.06476 9.31448 7.65054 8.7287L15.9399 0.43934C16.5257 -0.146447 17.4754 -0.146447 18.0612 0.43934L26.3506 8.7287C26.9364 9.31448 26.9364 10.2642 26.3506 10.85ZM2.0791 19.8946C2.90753 19.8946 3.5791 20.5662 3.5791 21.3946V28.0261C3.5791 28.5077 3.7704 28.9695 4.11092 29.31C4.45144 29.6506 4.91328 29.8419 5.39484 29.8419H28.605C29.0866 29.8419 29.5485 29.6506 29.889 29.31C30.2295 28.9695 30.4208 28.5077 30.4208 28.0261V21.3946C30.4208 20.5662 31.0924 19.8946 31.9208 19.8946C32.7492 19.8946 33.4208 20.5662 33.4208 21.3946V28.0261C33.4208 29.3033 32.9134 30.5282 32.0103 31.4314C31.1072 32.3345 29.8823 32.8419 28.605 32.8419H5.39484C4.11763 32.8419 2.89273 32.3345 1.9896 31.4314C1.08647 30.5282 0.579102 29.3033 0.579102 28.0261V21.3946C0.579102 20.5662 1.25067 19.8946 2.0791 19.8946Z" fill="#2560D7"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1rem] font-bold leading-5">Export Links To CSV</p>
        <p class="pt-1 text">Please enter details to export links.</p>
        <div class="pt-8 w-full">

          <b-dropdown ref="select_campaign" right class="w-full !pt-3 dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="true">
            <div :class="{'border !border-[#BE185D]': validations.campaigns}" class="w-full dropdown_header bg-[#F4F6FA] min-h-[3.5rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center" slot="button-content" data-cy="select-camp">
              <div>
                <p :class="[getExportLinks.campaigns.length ? '!text-[0.75rem]' : '!text-[0.875rem]']" class="text text-left">Select Campaigns</p>
                <div :class="{'mt-[0.5rem]': getExportLinks.campaigns.length}" class="flex flex-wrap gap-[0.5rem] w-full">
                  <div class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl" v-for="item in getExportLinks.campaigns">
                    <p class="text !text-[#3C4549] !leading-none">{{ getCampaignName(item) }}</p>
                    <svg @click.stop="removeCampaign(item)" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                      <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <span class="arrow_icon flex items-center ml-auto">
                <InputFieldMessage v-if="validations.campaigns" message="Please select the campaigns"></InputFieldMessage>
                <i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i>
              </span>
            </div>
            <ul class="clear inner w-full !px-0 !pb-2 !py-1">
              <template v-if="(tempCampaignList && tempCampaignList.length && tempCampaignList.length > 1) || search">
                <div class="mx-[1.5rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
                  <input placeholder="Search for campaign"
                         type="text"
                         class="w-full !h-[2.2rem] !border-none px-1"
                         v-model="search"
                         @input="searchFromCampaignList"
                         data-cy="search-camp"> <i
                  class="fal fa-search"></i>
                </div>
                <hr>
              </template>
              <template v-if="tempCampaignList && tempCampaignList.length">
                <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[1rem] flex justify-between font-poppins items-center">
                  <Checkbox
                    id="select_all_campaings_from_list"
                    label="All Columns"
                    v-model="isSelectAllCampaign"
                    @click="$refs.select_campaign.hide(true)"
                    @change="selectAllCampaigns(isSelectAllCampaign)"
                    labelClass="font-semibold text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
                <template v-for="(cta,index) in tempCampaignList">
                  <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                    <Checkbox
                    :id="cta._id"
                    :label="limitTextLength(cta.name, 21)"
                    v-model="getExportLinks.campaigns"
                    @click="$refs.select_campaign.hide(true)"
                    :inputValue="cta._id"
                    labelClass="text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
                </template>

              </template>
            </ul>
          </b-dropdown>

          <b-dropdown ref="select_columns" right class="w-full !pt-[0.75rem] dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="true">
            <div :class="{'border !border-[#BE185D]': validations.columns}" class="w-full dropdown_header bg-[#F4F6FA] min-h-[3.5rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center" slot="button-content" data-cy="select-camp">
              <div>
                <p :class="[selectedCsvColumns.length ? '!text-[0.75rem]' : '!text-[0.875rem]']" class="text text-left">Select Columns</p>
                <div :class="{'mt-[0.5rem]': selectedCsvColumns.length}" class="flex flex-wrap gap-y-[0.5rem] gap-x-[0.5rem] w-full">
                  <div class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl" v-for="item in selectedCsvColumns">
                    <p class="text !text-[#3C4549] !leading-none">{{ item }}</p>
                    <svg @click.stop="selectedCsvColumns.splice(item, 1)" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                      <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <span class="arrow_icon flex items-center ml-auto">
                <InputFieldMessage v-if="validations.columns" message="Please select the columns"></InputFieldMessage>
                <i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i>
              </span>
            </div>
            <ul class="clear inner w-full !px-0 !pb-2 !py-1">
              <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[1rem] flex justify-between font-poppins items-center">
                  <Checkbox
                    id="select_all_columns"
                    label="All Columns"
                    v-model="select_all_csv_export"
                    @click="$refs.select_columns.hide(true)"
                    @change="selectAllCsv()"
                    labelClass="font-semibold text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
              <template v-for="(column,index) in csvColumns">
                  <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                    <Checkbox
                      :id="'column_csv'+ index"
                      :label="column"
                      v-model="selectedCsvColumns"
                      @click="$refs.select_columns.hide(true)"
                      :inputValue="column"
                      labelClass="text-[#3C4549]"
                      customClass="w-full justify-between flex-row-reverse"
                    ></Checkbox>
                  </li>
                </template>
            </ul>
          </b-dropdown>

        </div>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
          id="login-button"
          type="button"
          class="text-white bg-[#2560D7] hover:bg-blue-700"
          buttonClass="btn-lg"
          @click.prevent="validateAndExportCSV()"
          :disabled="csvUploadLoader"
        >
          <template v-if="!csvUploadLoader" v-slot:label>Export Links</template>
          <template v-if="csvUploadLoader" v-slot:loader>
            <Loader color="text-white"></Loader>
          </template>
        </Button>
          <Button
          id="login-button"
          type="button"
          class="border border-gray-500 hover:bg-[#F2F3F8]"
          buttonClass="btn-lg"
          @click="close()"
        >
          <template v-slot:label>Close</template>
        </Button>
      </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LinksCSVExportModalV2',
  data () {
    return {
      selectedCsvBrands: [],
      selectedCsvCampaigns: [],
      select_all_csv_export: false,
      csvColumns: [
        'Shortened URL',
        'Original URL',
        'Campaign',
        'Clicks',
        'Conversions',
        'Conversion Rate',
        'Tags',
        'Unique Clicks',
        'Creation Date'
      ],
      selectedCsvColumns: [
        'Shortened URL',
        'Original URL',
        'Campaign',
        'Clicks',
        'Conversions',
        'Creation Date'
      ],
      csvUploadLoader: false,
      messages: {
        workspaceId: 'Workspace cannot be empty',
        campaigns: 'Campaigns cannot be empty',
        columns: 'Fields to be export cannot be empty'

      },
      validations: {
        brands: false,
        campaigns: false,
        columns: false

      },
      allCampaign: [],

      search: '',
      isSelectAllCampaign: true,
      tempCampaignList: []
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
  },
  methods: {
    ...mapActions(['fetchCampaignsList']),
    /**
     * Fetch all the required data for the model before model is opened
     * @description Fetch all the required data for the model
     * @returns {Promise<void>}
     */
    async preRequiredModelData() {
      this.allCampaign = await this.fetchCampaignsList({ page: 1, isFetchAll: true })
      this.selectAllCampaigns()
    },
    searchFromCampaignList() {
      if(this.search && this.search.length) {
        this.tempCampaignList = JSON.parse(JSON.stringify(this.allCampaign.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))))
        return
      }
      this.tempCampaignList = this.getFilteredCampaigns()
    },
    selectAllCampaigns() {
      if(this.isSelectAllCampaign) {
        this.tempCampaignList = this.getFilteredCampaigns()
        this.getExportLinks.campaigns = this.getFilteredCampaigns().map(item => item._id)
        return
      }
      this.getExportLinks.campaigns = []
    },
    getFilteredCampaigns () {
      return JSON.parse(JSON.stringify(this.allCampaign.filter(item => item.brand_id === this.getWorkspace._id)))
    },
    getCampaignName(id) {
      const cta = this.allCampaign.find(item => item._id === id)
      return cta ? cta.name : ''
    },
    removeCampaign(index) {
      this.getExportLinks.campaigns.splice(index, 1)
    },
    selectAllCsv () {
      if (this.select_all_csv_export) {
        this.selectedCsvColumns = JSON.parse(JSON.stringify(this.csvColumns))
        return
      }
      this.selectedCsvColumns = []
    },
    async validateAndExportCSV () {
      let columns = (this.selectedCsvColumns.filter(item => item !== null))
      this.validations.brands = !this.getWorkspace._id
      this.validations.campaigns = (this.getExportLinks.campaigns.length === 0)
      this.validations.columns = (columns.length === 0)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        const selectedCampaigns = this.allCampaign.map(item => this.getExportLinks.campaigns.includes(item._id) ? item : null).filter(item => item !== null)
        this.csvUploadLoader = true
        let res = await this.$store.dispatch('exportCSVLinks', {
          selection: this.selectedCsvColumns,
          campaigns: selectedCampaigns
        })
        if (res && res.data && res.data.status) {
          this.close()
        }
        this.csvUploadLoader = false
      }
    },
    close () {
      this.selectAllCampaigns()
      this.getExportLinks.brands = []
      this.validations = {
        brands: false,
        campaigns: false,
        columns: false
      }
      this.$bvModal.hide('links-csv-export-modal-v2')
    }
  },
  computed: {
    ...mapGetters([
      'getExportLinks',
      'getWorkspace'
    ])
  },
  watch: {
    'getExportLinks.campaigns.length' (value) {
      if (value && value > 0) this.validations.campaigns = false
      this.isSelectAllCampaign = this.tempCampaignList.length == value
    },
    'selectedCsvColumns.length' (value) {
      if (value && value > 0) this.validations.columns = false
      this.select_all_csv_export = this.selectedCsvColumns.length == this.csvColumns.length
    }
  }
}
</script>
